"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PromiseSignalList = void 0;
const ste_core_1 = require("ste-core");
const PromiseSignalDispatcher_1 = require("./PromiseSignalDispatcher");
/**
 * Storage class for multiple signal events that are accessible by name.
 * Events dispatchers are automatically created.
 */
class PromiseSignalList extends ste_core_1.EventListBase {
    /**
     * Creates a new SignalList instance.
     */
    constructor() {
        super();
    }
    /**
     * Creates a new dispatcher instance.
     */
    createDispatcher() {
        return new PromiseSignalDispatcher_1.PromiseSignalDispatcher();
    }
}
exports.PromiseSignalList = PromiseSignalList;
