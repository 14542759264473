"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SimpleEventHandlingBase = void 0;
const ste_core_1 = require("ste-core");
const SimpleEventList_1 = require("./SimpleEventList");
/**
 * Extends objects with signal event handling capabilities.
 */
class SimpleEventHandlingBase extends ste_core_1.HandlingBase {
    constructor() {
        super(new SimpleEventList_1.SimpleEventList());
    }
}
exports.SimpleEventHandlingBase = SimpleEventHandlingBase;
