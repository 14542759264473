"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PromiseSignalHandlingBase = void 0;
const ste_core_1 = require("ste-core");
const PromiseSignalList_1 = require("./PromiseSignalList");
/**
 * Extends objects with signal event handling capabilities.
 */
class PromiseSignalHandlingBase extends ste_core_1.HandlingBase {
    constructor() {
        super(new PromiseSignalList_1.PromiseSignalList());
    }
}
exports.PromiseSignalHandlingBase = PromiseSignalHandlingBase;
