"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PromiseSimpleEventHandlingBase = void 0;
const ste_core_1 = require("ste-core");
const PromiseSimpleEventList_1 = require("./PromiseSimpleEventList");
/**
 * Extends objects with signal event handling capabilities.
 */
class PromiseSimpleEventHandlingBase extends ste_core_1.HandlingBase {
    constructor() {
        super(new PromiseSimpleEventList_1.PromiseSimpleEventList());
    }
}
exports.PromiseSimpleEventHandlingBase = PromiseSimpleEventHandlingBase;
