"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EventHandlingBase = void 0;
const ste_core_1 = require("ste-core");
const EventList_1 = require("./EventList");
/**
 * Extends objects with signal event handling capabilities.
 */
class EventHandlingBase extends ste_core_1.HandlingBase {
    constructor() {
        super(new EventList_1.EventList());
    }
}
exports.EventHandlingBase = EventHandlingBase;
