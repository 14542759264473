"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EventList = void 0;
const ste_core_1 = require("ste-core");
const EventDispatcher_1 = require("./EventDispatcher");
/**
 * Storage class for multiple events that are accessible by name.
 * Events dispatchers are automatically created.
 */
class EventList extends ste_core_1.EventListBase {
    /**
     * Creates a new EventList instance.
     */
    constructor() {
        super();
    }
    /**
     * Creates a new dispatcher instance.
     */
    createDispatcher() {
        return new EventDispatcher_1.EventDispatcher();
    }
}
exports.EventList = EventList;
