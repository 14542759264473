'use strict';

Object.defineProperty(exports, '__esModule', { value: true });

var config = {
  paths: {
    vs: 'https://cdn.jsdelivr.net/npm/monaco-editor@0.36.1/min/vs'
  }
};

exports.default = config;
