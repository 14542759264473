"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PromiseEventHandlingBase = void 0;
const ste_core_1 = require("ste-core");
const PromiseEventList_1 = require("./PromiseEventList");
/**
 * Extends objects with signal event handling capabilities.
 */
class PromiseEventHandlingBase extends ste_core_1.HandlingBase {
    constructor() {
        super(new PromiseEventList_1.PromiseEventList());
    }
}
exports.PromiseEventHandlingBase = PromiseEventHandlingBase;
